.taste-bg.tasteStyle_001 {
  background-color: #685ec4;
}

.taste-bg.tasteStyle_002 {
  background-color: #dcaabf;
}

.taste-bg.tasteStyle_003 {
  background-color: #ce106e;
}

.taste-bg.tasteStyle_004 {
  background-color: #fa511f;
}

.taste-bg.tasteStyle_005 {
  background-color: #6d3332;
  color: #fff;
}

.taste-bg.tasteStyle_006 {
  background-color: #f8e848;
}

.taste-bg.tasteStyle_007 {
  background-color: #f07800;
}

.taste-bg.tasteStyle_008 {
  background-color: #5bae33;
  color: #fff;
}

.taste-bg.tasteStyle_009 {
  background-color: #1281a6;
  color: #fff;
}

.taste-bg.tasteStyle_010 {
  background-color: #a05020;
  color: #fff;
}

.taste-bg.tasteStyle_011 {
  background-color: #fbe37f;
}

.taste-bg.tasteStyle_012 {
  background-color: #31984f;
}

.taste-bg.tasteStyle_013 {
  background-color: #e99926;
}

.taste-bg.tasteStyle_014 {
  background-color: #4d6eb1;
}

.taste-bg.tasteStyle_015 {
  background-color: #774314;
}

.taste-bg.tasteStyle_ {
  background-color: #0ff;
}

.taste-bg.sparklingTasteStyle_006 {
  background-color: #f8e848;
}

.taste-bg.sparklingTasteStyle_007 {
  background-color: #f07800;
}

.taste-bg.sparklingTasteStyle_008 {
  background-color: #5bae33;
}

.taste-bg.sparklingTasteStyle_009 {
  background-color: #1281a6;
}

.taste-bg.sparklingTasteStyle_010 {
  background-color: #a05020;
}

.taste-bg.rtdTasteStyle_016,
.taste-bg.rtdtastestyle_016 {
  background-color: #abe1fb;
}

.taste-bg.rtdTasteStyle_017,
.taste-bg.rtdtastestyle_017 {
  background-color: #f7ee61;
}

.taste-bg.rtdTasteStyle_018,
.taste-bg.rtdtastestyle_018 {
  background-color: #f37022;
}

.taste-bg.rtdTasteStyle_019,
.taste-bg.rtdtastestyle_019 {
  background-color: #ee3d97;
}

.taste-bg.rtdTasteStyle_020,
.taste-bg.rtdtastestyle_020 {
  background-color: #a25c98;
}

.taste-bg.tasteStyle_432 {
  background-color: #ecc300;
}

.taste-bg.tasteStyle_433 {
  background-color: #ff7300;
}

.taste-bg.tasteStyle_434 {
  background-color: #89532f;
}

.taste-bg.tasteStyle_435 {
  background-color: #7b858a;
}

.taste-bg.tasteStyle_441 {
  background-color: #ebc22b;
}

.taste-bg.tasteStyle_443 {
  background-color: #885333;
}

.taste-bg.tasteStyle_445 {
  background-color: #6cc24a;
}

.taste-bg.tasteStyle_447 {
  background-color: #0085ad;
}

.taste-bg.tasteStyle_449 {
  background-color: #ea0437;
}

.taste-bg.tasteStyle_459 {
  background-color: #7b858a;
}
