@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Oswald-Regular.woff) format("woff");
}

body {
  margin: 0;
  font: 10pt/1.1 -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.price-col,
.number-col,
.score-col {
  text-align: right;
}

.stars-0,
.stars-1 {
  background: rgba(215, 25, 28, 0.5);
}

.stars-2 {
  background: rgba(253, 174, 97, 0.5);
}

.stars-3 {
  background: rgba(255, 255, 191, 0.5);
}

.stars-4 {
  background: rgba(166, 217, 106, 0.5);
}

.stars-5 {
  background: rgba(26, 150, 65, 0.5);
}

.white-wine {
  background: rgb(255, 240, 214);
}

.red-wine {
  background: rgb(147, 16, 42);
  color: #fff;
}

.spark-wine {
  background: rgb(174, 232, 255);
}

.rose-wine {
  background: #ffc5be;
}

.detail-table {
  border-collapse: collapse;
}

.detail-table td {
  border: 1px solid #555;
  padding: 0.5em;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.loading-indicator-animation {
  animation: animation 1500ms linear infinite both alternate;
  will-change: transform;
  will-change: opacity;
}

/* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bl%3A1%2Cs%3A%5B%7BT%3A%22t%22%2Ce%3A%22b%22%2Cd%3A600%2CD%3A0%2Cf%3A%7Bx%3A0%2Cy%3A-100%7D%2Ct%3A%7Bx%3A0%2Cy%3A0%7D%2Cs%3A2%2Cb%3A2%7D%2C%7BT%3A%22c%22%2Ce%3A%22s%22%2Cd%3A800%2CD%3A65%2Cf%3A%7Bx%3A1%2Cy%3A1%7D%2Ct%3A%7Bx%3A0.1%2Cy%3A1.7%7D%2Cs%3A2%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22s%22%2Cd%3A300%2CD%3A30%2Cf%3A%7Bx%3A1%2Cy%3A1%7D%2Ct%3A%7Bx%3A1.5%2Cy%3A1%7D%2Cs%3A3%2Cb%3A4%7D%2C%7BT%3A%22r%22%2Ce%3A%22b%22%2Cd%3A1200%2CD%3A0%2Cf%3A-35%2Ct%3A0%2Cs%3A3%2Cb%3A4%7D%5D%7D */

@keyframes animation {
  0% {
    opacity: 0;
    transform: matrix3d(
      0.819,
      -0.574,
      0,
      0,
      0.574,
      0.819,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -100,
      0,
      1
    );
  }
  1.8% {
    transform: matrix3d(
      0.889,
      -0.457,
      0,
      0,
      0.457,
      0.889,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -67.21,
      0,
      1
    );
  }
  2.4% {
    transform: matrix3d(
      0.909,
      -0.417,
      0,
      0,
      0.417,
      0.909,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -57.81,
      0,
      1
    );
  }
  2.5% {
    transform: matrix3d(
      0.912,
      -0.41,
      0,
      0,
      0.41,
      0.912,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -56.317,
      0,
      1
    );
  }
  3.3% {
    transform: matrix3d(
      1.058,
      -0.357,
      0,
      0,
      0.404,
      0.934,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -45.182,
      0,
      1
    );
  }
  3.6% {
    transform: matrix3d(
      1.086,
      -0.337,
      0,
      0,
      0.389,
      0.941,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -41.35,
      0,
      1
    );
  }
  4.1% {
    transform: matrix3d(
      1.11,
      -0.305,
      0,
      0,
      0.356,
      0.952,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -35.493,
      0,
      1
    );
  }
  4.3% {
    transform: matrix3d(
      1.113,
      -0.292,
      0,
      0,
      0.34,
      0.956,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -33.264,
      0,
      1
    );
  }
  5.32% {
    transform: matrix3d(
      1.095,
      -0.231,
      0,
      0,
      0.26,
      0.973,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -23.42,
      0,
      1
    );
  }
  5.41% {
    transform: matrix3d(
      1.092,
      -0.226,
      0,
      0,
      0.254,
      0.974,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -22.656,
      0,
      1
    );
  }
  5.42% {
    transform: matrix3d(
      1.091,
      -0.226,
      0,
      0,
      0.253,
      0.974,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -22.56,
      0,
      1
    );
  }
  5.68% {
    transform: matrix3d(
      1.035,
      -0.218,
      0,
      0,
      0.223,
      1.01,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -20.4,
      0,
      1
    );
  }
  7.16% {
    transform: matrix3d(
      0.797,
      -0.159,
      0,
      0,
      0.109,
      1.163,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -10.469,
      0,
      1
    );
  }
  7.23% {
    transform: matrix3d(
      0.789,
      -0.155,
      0,
      0,
      0.105,
      1.169,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -10.073,
      0,
      1
    );
  }
  7.82% {
    transform: matrix3d(
      0.733,
      -0.128,
      0,
      0,
      0.078,
      1.208,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -7.143,
      0,
      1
    );
  }
  8.61% {
    transform: matrix3d(
      0.682,
      -0.092,
      0,
      0,
      0.051,
      1.243,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -3.943,
      0,
      1
    );
  }
  8.81% {
    transform: matrix3d(
      0.674,
      -0.084,
      0,
      0,
      0.045,
      1.25,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -3.26,
      0,
      1
    );
  }
  10.22% {
    transform: matrix3d(
      0.648,
      -0.028,
      0,
      0,
      0.014,
      1.27,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.46,
      0,
      1
    );
  }
  10.36% {
    transform: matrix3d(
      0.648,
      -0.023,
      0,
      0,
      0.012,
      1.269,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.723,
      0,
      1
    );
  }
  10.71% {
    transform: matrix3d(
      0.65,
      -0.012,
      0,
      0,
      0.006,
      1.268,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1.336,
      0,
      1
    );
  }
  12.91% {
    transform: matrix3d(
      0.706,
      0.039,
      0,
      0,
      -0.023,
      1.226,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      3.426,
      0,
      1
    );
  }
  14.26% {
    transform: matrix3d(
      0.758,
      0.055,
      0,
      0,
      -0.035,
      1.186,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      3.685,
      0,
      1
    );
  }
  14.43% {
    transform: matrix3d(
      0.765,
      0.056,
      0,
      0,
      -0.036,
      1.181,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      3.682,
      0,
      1
    );
  }
  16.61% {
    transform: matrix3d(
      0.853,
      0.063,
      0,
      0,
      -0.048,
      1.112,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      3.212,
      0,
      1
    );
  }
  17.22% {
    transform: matrix3d(
      0.875,
      0.062,
      0,
      0,
      -0.05,
      1.095,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      2.996,
      0,
      1
    );
  }
  18.56% {
    transform: matrix3d(
      0.92,
      0.059,
      0,
      0,
      -0.051,
      1.06,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      2.469,
      0,
      1
    );
  }
  22.77% {
    transform: matrix3d(
      1.008,
      0.038,
      0,
      0,
      -0.038,
      0.992,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1
    );
  }
  22.87% {
    transform: matrix3d(
      1.009,
      0.037,
      0,
      0,
      -0.038,
      0.992,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.972,
      0,
      1
    );
  }
  24.27% {
    opacity: 1;
    transform: matrix3d(
      1.021,
      0.03,
      0,
      0,
      -0.031,
      0.982,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.64,
      0,
      1
    );
  }
  26.9% {
    transform: matrix3d(
      1.029,
      0.018,
      0,
      0,
      -0.019,
      0.977,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.227,
      0,
      1
    );
  }
  27.5% {
    transform: matrix3d(
      1.029,
      0.015,
      0,
      0,
      -0.016,
      0.977,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.165,
      0,
      1
    );
  }
  28.33% {
    transform: matrix3d(
      1.028,
      0.012,
      0,
      0,
      -0.013,
      0.978,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0.095,
      0,
      1
    );
  }
  34.28% {
    transform: matrix3d(
      1.009,
      -0.001,
      0,
      0,
      0.001,
      0.993,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -0.07,
      0,
      1
    );
  }
  35.25% {
    transform: matrix3d(
      1.007,
      -0.002,
      0,
      0,
      0.002,
      0.995,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -0.068,
      0,
      1
    );
  }
  39.44% {
    transform: matrix3d(
      0.999,
      -0.004,
      0,
      0,
      0.004,
      1.001,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -0.04,
      0,
      1
    );
  }
  43.59% {
    transform: matrix3d(
      0.998,
      -0.003,
      0,
      0,
      0.003,
      1.002,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      -0.015,
      0,
      1
    );
  }
  50% {
    transform: matrix3d(
      0.999,
      -0.001,
      0,
      0,
      0.001,
      1.001,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  60.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  61.66% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.08% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  83.98% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
